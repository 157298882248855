.example-enter {
	opacity: 0;
}
.example-enter-active {
	opacity: 1;
	transition: opacity 300ms ease;
}
.example-exit {
	opacity: 1;
}
.example-exit-active {
	opacity: 0;
	transition: opacity 300ms ease;
}
